

































































































































































































































































































































import {
  SlimChannelInfo,
  TargetState,
  TestChannelStateCode
} from '@/includes/types/Board.types'
import { BoardService } from '@/includes/services/BoardService'
import { ChannelService } from '@/includes/services/ChannelService'
import ChannelCard from '@/components/ChannelCard.vue'
import TestStatusInfo from '@/components/TestStatusInfo.vue'
import TestStateMixin from '@/mixins/TestStateMixin'
import { errorNotification } from '@/includes/services/NotificationService'
import LogChannelGuide from '@/components/HelpMessages/ChannelsGuide/LogChannelGuide.vue'
import InactiveChannelGuide from '@/components/HelpMessages/ChannelsGuide/InactiveChannelGuide.vue'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import FieldTitle from 'piramis-base-components/src/components/Pi/components/FieldTitle.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters([ 'getBoardById' ])
  },
  components: {
    InactiveChannelGuide,
    LogChannelGuide,
    ChannelCard,
    TestStatusInfo,
    EmptyData,
    HelpMessage,
    FieldTitle,
    HighlightAnchor
  },
  data() {
    return {
      TargetState,
      EntityTypes
    }
  }
})
export default class BoardChannels extends Mixins<TestStateMixin>(TestStateMixin) {
  getBoardById!: GetBoardByIdType

  allChannels: Array<SlimChannelInfo> = []

  isMounted = false

  activeChannelId: number | null = null

  isPopupActive = false

  isLoading = false

  get inactiveChannels(): Array<SlimChannelInfo> {
    return this.allChannels
      .filter((channel) => channel.state === TargetState.None)
  }

  get boardChannels(): Array<SlimChannelInfo> {
    return this.allChannels
      .filter((channel) => channel.state === TargetState.Board)
      .sort((a, b) => b.members_count - a.members_count)
  }

  get logChannel(): Array<SlimChannelInfo> {
    return this.allChannels
      .filter((channel) => channel.state === TargetState.Log)
  }

  handleOnChannelStateButtonClick(channelId: number): void {
    this.activeChannelId = channelId
    this.isPopupActive = true
  }

  handleUpdateChannelTestState(channelInfo: { state: TestChannelStateCode, channelId: number, boardId: string }): void {
    this.allChannels = this.allChannels.map(value => {
      if (value.id === channelInfo.channelId) {
        return {
          ...value,
          test_state: channelInfo.state
        }
      }
      return value
    })
  }

  goToByRouteName(routeName: string): void {
    this.$router.push({
      name: routeName,
      params: {
        id: this.$store.getters.activeBoard!.board.split('.')[0]
      }
    })
  }

  updateChannelState(new_state: TargetState, channelId: number): void {
    this.isPopupActive = false
    this.isLoading = true
    ChannelService.updateChannelState('tg', {
      board_key: this.getBoardById(this.$route.params[ EntityTypes.BOT_ID ])!.board,
      channel_id: channelId,
      new_state: new_state
    })
      .then(() => this.getBoardChannels())
      .catch(errorNotification)
      .finally(() => this.isLoading = false)
  }

  async getBoardChannels(): Promise<void> {
    try {
      this.isLoading = true
      const { channels } = await BoardService.getBoardChannels('tg', { board_key: this.getBoardById(this.$route.params[ EntityTypes.BOT_ID ])!.board })
      this.allChannels = channels
    } catch (error: any) {
      errorNotification(error)
    } finally {
      this.isLoading = false
      this.isMounted = true
    }
  }

  deleteChannelWarn(id: number) {
    this.$confirm({
      title: this.$t('delete_channel_warn_title'),
      content: this.$t('delete_channel_warn_text'),
      onOk: () => this.deleteChannel(id)
    })
  }

  deleteChannel(id: number) {
    this.$baseTemplate.loader.open()

    ChannelService.deleteChannel('tg', {
      board_key: this.$store.getters.activeBoard.board,
      id
    })
      .then(() => {
        this.allChannels = this.allChannels.filter(c => c.id !== id)
      })
      .catch(errorNotification)
      .finally(() => {
        this.$baseTemplate.loader.close()
      })
  }

  async mounted(): Promise<void> {
    await this.getBoardChannels()
  }
}
