








































import { BoardPermissions, ChannelInfo } from '@/includes/types/Board.types'
import { hasChangeConfigPermission } from '@/includes/PermissionHelper'
import TestStateMixin from '@/mixins/TestStateMixin'
import TestStatusInfo from '@/components/TestStatusInfo.vue'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    TestStatusInfo
  },
  data() {
    return {
      hasChangeConfigPermission
    }
  }
})
export default class ChannelCardState extends Mixins(TestStateMixin) {

  @Prop() type!: 'large' | 'slim'

  @Prop({ required: true, type: Object }) channel !: ChannelInfo

  @Prop({ required: false, type: String }) boardKey!: string

  @Prop({ type: Array, default: () => [] }) permissions!:Array<BoardPermissions> | null

  onChannelStateClick() {
    this.handleOnChannelStateClick({ state: this.channel.test_state, entity: 'channel' })
  }

  handleTestChannelStatusClick(event: Event): void {
    if (this.buttonDisabled) {
      event.preventDefault()
    } else {
      this.testChannelStatus(this.boardKey, this.channel.id)
    }
  }

  created() {
    if (this.channel.test_state === 'None' && this.$store.state.testStateCounter < 3) {
      this.$store.commit('updateTestStateCounter')
      this.testChannelStatus(this.boardKey, this.channel.id)
    }
  }
}
